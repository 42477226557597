<template>
    <div class="burger-container">
        <button class="burger-logo" @click="$inertia.visit(route('index'))">
            <img
                alt="logo"
                width="48"
                height="48"
                src="../../../resources/images/logo-small.webp"
            />
        </button>
        <div class="burger">
            <div class="burger-icon" @click="toggleMenu">
                <div class="burger-icon__circle">
                    <div class="burger-icon__bars">
                        <div
                            class="burger-icon__top burger-icon__bar"
                            :class="{
                                'burger-icon__top_active': isBurgerMenuOpened,
                            }"
                        ></div>
                        <div
                            class="burger-icon__center burger-icon__bar"
                            :class="{
                                'burger-icon__center_active':
                                    isBurgerMenuOpened,
                            }"
                        ></div>
                        <div
                            class="burger-icon__bottom burger-icon__bar"
                            :class="{
                                'burger-icon__bottom_active':
                                    isBurgerMenuOpened,
                            }"
                        ></div>
                    </div>
                </div>
            </div>
        </div>
        <transition name="fade">
            <div class="burger-menu" v-show="isBurgerMenuOpened">
                <nav class="burger-menu__nav">
                    <ul class="burger-menu__main-list burger-menu__list">
                        <li
                            v-for="item in menuItems"
                            :key="item.title"
                            class="burger-menu__main-list-item"
                        >
                            <Link
                                class="burger-menu__main-link"
                                :href="item.href"
                                @click.prevent="toggleMenu"
                            >
                                {{ item.title }}
                            </Link>
                        </li>
                    </ul>
                </nav>
                <div class="burger-menu__button-wrapper">
                    <button
                        class="burger-menu__button"
                        @click="getStartedButtonClicked"
                    >
                        Get Started
                    </button>
                </div>
            </div>
        </transition>
    </div>
</template>

<script>
import {Link} from '@inertiajs/vue3'
import {MENU_ITEMS, START_NOW_LINK} from '@/Components/menuItems.js'
import {trackEvent} from '@/Utils/analytics.js'

export default {
    components: {
        Link,
    },
    data() {
        return {
            isBurgerMenuOpened: false,
            menuItems: MENU_ITEMS,
            startNowLink: START_NOW_LINK,
        }
    },
    methods: {
        toggleMenu() {
            this.isBurgerMenuOpened = !this.isBurgerMenuOpened

            const body = document.querySelector('body')
            let scrollPosition = 0

            if (this.isBurgerMenuOpened) {
                scrollPosition = window.scrollY
                body.style.overflow = 'hidden'
                body.style.position = 'fixed'
                body.style.top = `-${scrollPosition}px`
                body.style.width = '100%'
            } else {
                body.style.removeProperty('overflow')
                body.style.removeProperty('position')
                body.style.removeProperty('top')
                body.style.removeProperty('width')
                window.scrollTo(0, scrollPosition)
            }
        },
        getStartedButtonClicked() {
            trackEvent('start_page_pressed')
            trackEvent('get_started', null, 'fb')

            this.$inertia.visit(this.startNowLink)
        },
    },
}
</script>

<style lang="scss" scoped>
.burger-container {
    @apply w-[375px] relative;
    @screen lg {
        @apply hidden;
    }
}

.burger-logo {
    @apply absolute z-50 top-[-76px] left-[20px];
}

.burger {
    @apply absolute z-50 top-[-68px] right-[12px] cursor-pointer;
}

.burger-icon__circle {
    @apply flex justify-center items-center w-[40px] h-[40px];
}

.burger-icon__bars {
    @apply w-[32px] h-[21px];
}

.burger-icon__bar {
    background-color: #0fab6d;
    transition: 0.4s cubic-bezier(0.68, -0.6, 0.32, 1.6);
}

.burger-icon__top {
    @apply w-[23px] h-[3px] mb-[6px] ml-auto;
}

.burger-icon__top_active {
    @apply w-[23px] origin-bottom mb-0;
    transform: rotatez(-45deg) translate(-1px, 0px);
}

.burger-icon__center {
    @apply w-[32px] h-[3px] mb-[6px];
}

.burger-icon__center_active {
    @apply hidden;
}

.burger-icon__bottom {
    @apply w-[23px] h-[3px] ml-auto;
}

.burger-icon__bottom_active {
    @apply w-[23px] origin-bottom;
    transform: rotatez(45deg) translate(-3px, 0px);
}

.burger-menu {
    @apply min-h-screen w-screen fixed top-[0px] left-[0px] z-10 overflow-y-auto bg-[#0E0E0E];
    background-size: 100% auto;
    background-repeat: no-repeat;
    background-position: top center;
    background-image: image-set(
            url('../../../resources/images/menu/375/menu-bg.svg') 1x
        ),
        image-set(url('../../../resources/images/menu/375/menu-bg.svg') 1x);
}

.burger-menu__main-list {
    @apply w-[375px] mt-[160px] mx-auto pl-[20px];
}

.burger-menu__main-list-item {
    @apply flex mb-[24px];
}

.burger-menu__main-link {
    @apply font-sans text-[24px] font-normal leading-[32px] tracking-[0px] text-left text-white;
    &:hover {
        @apply underline;
    }
}

.burger-menu__button-wrapper {
    @apply flex justify-center mt-[130px];
}

.burger-menu__button {
    @apply font-sans w-[335px] h-[68px] text-[20px] font-bold leading-[24px] tracking-[0px] text-center text-white rounded-[16px];
    background: linear-gradient(90deg, #0fab6d 0%, #6c3da9 100%);
    box-shadow: 0 0 4px 0 #00000018;
    transition: background 1s ease-out;

    &:hover {
        background: linear-gradient(270deg, #0fab6d 0%, #6c3da9 100%);
    }
}

.fade-enter-from,
.fade-leave-to {
    @apply opacity-0;
}

.fade-enter-to,
.fade-leave-from {
    @apply opacity-100;
}

.fade-enter-active,
.fade-leave-active {
    transition: opacity 0.5s;
}
</style>
